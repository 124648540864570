import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import logo from "../images/product/logo.svg"

import { Container } from "../components/global"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundContainer>
      <h1>Oops...</h1>
      <p>
        You just hit a route that doesn&#39;t exist... Go back{" "}
        <Link to="/" style={{ textDecoration: "none", color: "#098b8c" }}>
          home
        </Link>
        ?
      </p>
      <ImageWrapper>
        <img src={logo} alt="Logo" style={{ width: "150px" }} />
      </ImageWrapper>
    </NotFoundContainer>
  </Layout>
)

const NotFoundContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const ImageWrapper = styled.div`
  display: inline;
`

export default NotFoundPage
